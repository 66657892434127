<template>
    <div>
        <div class="header">
            <div class="head">
                <div class="logo">
                    <a href="Paper/Index/index"><img src="../assets/demo/logo.png" alt=""></a>
                </div>
                <div class="menu">
                    <a class="about" href="Paper/Index/page_about">关于我们</a>
                    <a class="login" href="Paper/Index/login">登录</a>
                    <a class="register" href="Users/register">注册</a>
                </div>
            </div>
        </div>
        <div class="banner">
            <div class="swiper-container" id="swiper-container">
                <div class="swiper-wrapper">
                    <div class="swiper-slide"><img src="../assets/demo/banner1.png" alt=""></div>
                    <!-- <div class="swiper-slide"><img src="../assets/demo/banner2.png" alt=""></div>
                    <div class="swiper-slide"><img src="../assets/demo/banner3.png" alt=""></div> -->
                </div>
                <div class="swiper-button-prev swiper-button-white"></div><!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                <div class="swiper-button-next swiper-button-white"></div><!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
                <div class="swiper-pagination"></div>
            </div>
        </div>
        <div class="area1-bg">
            <div class="area1">
                <div class="area1-left">
                    <img src="../assets/demo/area1-left.png" alt="">
                </div>
                <div class="area1-right">
                    <div class="title">
                        <img src="../assets/demo/title1.png" alt="">
                        <p>核心技术：纸质云系统</p>
                        <span>平台首创纸质规范标准化系统</span>
                    </div>
                    <div class="area1-content">
                        统一规范标准纸质代号、纸板命名和纸度排程实时共享的操作系统，客户询价、采购更便捷。
                    </div>
                </div>
            </div>
        </div>
        <div class="area2-bg">
            <div class="area2">
                <div class="area2-title">
                    <div class="title">
                        <img src="../assets/demo/title2.png" alt="">
                        <p>我的管理系统</p>
                        <span>更省心、更放心</span>
                    </div>
                </div>
                <div class="area2-content">
                    <div class="area2-content-left">
                        <ul>
                            <li>
                                <div class="left">
                                    <p>报价单管理</p>
                                    <span>产品最新价格记录、调整及下单操作</span>
                                </div>
                                <div class="right"><img src="../assets/demo/area2-1.png" alt=""></div>
                            </li>
                            <li>
                                <div class="left">
                                    <p>订单管理</p>
                                    <span>实时显示订单状态、完成状况</span>
                                </div>
                                <div class="right"><img src="../assets/demo/area2-2.png" alt=""></div>
                            </li>
                            <li>
                                <div class="left">
                                    <p>对账单管理</p>
                                    <span>可根据自定义的对账周期，自动生成对账单</span>
                                </div>
                                <div class="right"><img src="../assets/demo/area2-3.png" alt=""></div>
                            </li>
                        </ul>
                    </div>
                    <div class="area2-content-center"><img src="../assets/demo/area2-content.png" alt=""></div>
                    <div class="area2-content-right">
                        <ul>
                            <li>
                                <div class="left"><img src="../assets/demo/area2-4.png" alt=""></div>
                                <div class="right">
                                    <p>发货单管理</p>
                                    <span>自动添加生成送货单</span>
                                </div>
                            </li>
                            <li>
                                <div class="left"><img src="../assets/demo/area2-5.png" alt=""></div>
                                <div class="right">
                                    <p>收货单管理</p>
                                    <span>支持一键签收，实时生成收货记录</span>
                                </div>
                            </li>
                            <li>
                                <div class="left"><img src="../assets/demo/area2-6.png" alt=""></div>
                                <div class="right">
                                    <p>评价记录</p>
                                    <span>每月可对合作方的合作情况进行评价</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="area3-bg">
            <div class="area3">
                <div class="area3-title">物流系统服务</div>
                <div class="area3-content">整合产业链专业运力资源，实时发布对接企业货运需求，快速匹配对接附近的货车资源提供低价优质的货运服务，大大提高企业和货运资源供需双方的效率。（规划中）
                </div>
            </div>
        </div>
        <div class="area4-bg">
            <div class="area4">
                <div class="area4-left">
                    <img src="../assets/demo/area4-left.png" alt="">
                </div>
                <div class="area4-right">
                    <div class="title">
                        <img src="../assets/demo/title3.png" alt="">
                        <p>区块链记账系统</p>
                        <span>纸产业链企业运营的合作伙伴</span>
                    </div>
                    <div class="area4-content">
                        区块链记账系统：管理记录金融机构所需的相关企业运作具体操作节点、评分及数据形成企业数字资产，为产业链金融服务提供有力的信用依据，从而解决企业融资问题。（规划中）
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div styl="width:100%;">
                <div class="m-foot-left" style="padding-top:20px;">
                    <div class="m-foot-left-title">咨询电话：</div>
                    <div class="m-foot-left-content">0769-8307 7877 <span>转</span> 805</div>
                    <div class="m-foot-left-title">电子邮箱：</div>
                    <div class="m-foot-left-content">service@zxyouni.com</div>
                </div>
                <div class="m-foot-right" style="display: flex;padding-top:10px;">
                    <div style="display:inline-flex;width:100px;">
                        <span style="padding-bottom:10px;">
                            <img src="../assets/demo/qcode1.jpg" alt=""/>
                            采购端小程序
                        </span>
                    </div>
                    <div style="display:inline-flex;width:100px;">
                        <span style="padding-bottom:10px;">
                            <img src="../assets/demo/qcode2.jpg" alt=""/>
                            纸箱端小程序
                        </span>
                    </div>
                    <div style="display:inline-flex;width:100px;">
                        <span style="padding-bottom:10px;">
                            <img src="../assets/demo/qcode3.jpg" alt=""/>
                            纸板端小程序
                        </span>
                    </div>
                    <div style="display:inline-flex;width:100px;">
                        <span style="padding-bottom:10px;">
                            <img src="../assets/demo/qcode4.jpg" alt=""/>
                            微信公众号
                        </span>
                    </div>
                </div>
            </div>

            <div class="m-copy" style="clear:both;padding-top:100px;">
                <div class="m-copyright">Copyright ©2017-2019. 东莞有和网络信息有限公司 版权所有 粤ICP备17148400号</div>
            </div>
            <div class="m-foot-menu">
                <ul>
                    <li><a href="Paper/Index/page_about">关于我们</a></li>
                    <li><a href="Paper/Index/page_contact">联系我们</a></li>
                    <li><a href="Paper/Index/page_law">法律声明</a></li>
                    <li><a href="Paper/Index/page_service">服务条款</a></li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import '../assets/home.css'
export default {
    name: 'HomePage'
}
</script>
<style scoped>
.m-foot-left {
    float: left;
    width: 50%;
}

.m-foot-right {
    float: right;
    width:50%;
}

.m-foot-right ul {
    list-style: none;
}

.m-foot-right ul li {
    display: inline;
}
.m-foot-right div {
    margin-left:10px;
}
.m-foot-right div img {
    width: 100px;
    height:100px;
}

.m-foot-menu {
    padding: 10px 0px 10px 0px;
}

.m-foot-menu ul {
    list-style: none;
    margin-left: 50px;
}

.m-foot-menu ul li {
    display: inline;
}

.m-foot-menu ul li a {
    color: black;
    margin-left: 10px;
    margin-right: 10px;
}
</style>